import {
  OutputSelector,
  PayloadAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import addMinutes from "date-fns/addMinutes";
import { getCRSRatesASE } from "../../../services/rates";
import { Storage } from "../../../utils/storage";
import { AppDispatch, AppThunk } from "../../store";
import { addHotelAmenities } from "../Hotel/hotel";
import { TP_REDEMPTION_RATE } from "../../../@types/Constants";

const _initialState = {};

const rateSlice = createSlice({
  name: "rate",
  initialState: _initialState,
  reducers: {
    saveRates: (state: any, { payload }: PayloadAction<any>) => {
      state[payload.crsCode] = payload;
      return state;
    },
    clearRates: (state: any, { payload }: PayloadAction<any>) => {
      return payload;
    },
  },
});

const specialPriceSlice = createSlice({
  name: "specialPrice",
  initialState: false,
  reducers: {
    unlockPricing: (state: any, { payload }: PayloadAction<any>) => {
      Storage.SetLocalStorageValue("HR-discount-unlocked", true);
      return { ...state, payload };
    },
  },
});

const ratePlanFilterSlice = createSlice({
  name: "ratePlanFilter",
  initialState: "",
  reducers: {
    saveRatePlanFilter: (_state: any, { payload }: PayloadAction<any>) => {
      return payload;
    },
  },
});

export const ratesReducer = rateSlice.reducer;
export const { saveRates, clearRates } = rateSlice.actions;

export const specialPricingReducer = specialPriceSlice.reducer;
export const { unlockPricing } = specialPriceSlice.actions;

export const ratePlanFilterReducer = ratePlanFilterSlice.reducer;
export const { saveRatePlanFilter } = ratePlanFilterSlice.actions;

//Async actions

export const addRatesASE =
  (
    apiUrl: string,
    adults: number,
    children: number,
    chidlrenAges: string,
    crsHotelCode: string,
    chainId: string,
    start: string,
    end: string,
    availReqType: string,
    promotionCode: string,
    ratePlanCode: string,
    ratePlanFilterCode: string,
    isLoggedIn: boolean,
    isHotelPage: boolean,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    abortController: any
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await getCRSRatesASE(
      adults,
      children,
      chidlrenAges,
      crsHotelCode,
      chainId,
      start,
      end,
      availReqType,
      promotionCode,
      ratePlanCode,
      ratePlanFilterCode,
      abortController
    );
    if (response) {
      const hasOtherRateCodes =
        response.rooms &&
        response.rooms.some((room) =>
          room.roomRates.some(
            (rate) => !TP_REDEMPTION_RATE.includes(rate.rateCode)
          )
        );

      const hasOnlyTPRNRRateCodes =
        response.rooms &&
        response.rooms.every((room) =>
          room.roomRates.every((rate) =>
            TP_REDEMPTION_RATE.includes(rate.rateCode)
          )
        );
      if (
        isHotelPage &&
        ["AAA", "GOV", "PKG"].includes(ratePlanFilterCode) &&
        (response?.rooms?.length === 0 ||
          (!hasOtherRateCodes && !isLoggedIn) ||
          (hasOnlyTPRNRRateCodes && isLoggedIn) ||
          !response.rooms)
      ) {
        const reAvailablilityResponse = await getCRSRatesASE(
          adults,
          children,
          chidlrenAges,
          crsHotelCode,
          chainId,
          start,
          end,
          availReqType,
          promotionCode,
          ratePlanCode,
          "Retail",
          abortController
        );

        if (reAvailablilityResponse) {
          const rates = {
            Name: reAvailablilityResponse.name,
            Rooms: reAvailablilityResponse.rooms,
            reTriggerAvailability: true,
            redemptionItem: reAvailablilityResponse.redemptionItem,
            hotelLocation: reAvailablilityResponse?.hotelLocation,
            Brand: reAvailablilityResponse?.brand.code,
          };

          dispatch(
            rateSlice.actions.saveRates({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ...rates,
              crsCode: crsHotelCode,
              expireCache: rates !== null ? addMinutes(new Date(), 5) : null,
              searchQuery: rates !== null ? apiUrl : null,
            })
          );
          dispatch(addHotelAmenities(reAvailablilityResponse));
        }
      } else {
        const rates = {
          Name: response.name,
          Rooms: response.rooms,
          reTriggerAvailability: false,
          redemptionItem: response.redemptionItem,
          hotelLocation: response?.hotelLocation,
          Brand: response?.brand.code,
        };

        dispatch(
          rateSlice.actions.saveRates({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...rates,
            crsCode: crsHotelCode,
            expireCache: rates !== null ? addMinutes(new Date(), 5) : null,
            searchQuery: rates !== null ? apiUrl : null,
          })
        );
        dispatch(addHotelAmenities(response));
      }
    }
  };

//Selectors
const selectAllRates = (state: { rates: any }): any => state.rates;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const selectHotelRates = (
  codes: any[]
): OutputSelector<
  {
    rates: any;
  },
  any,
  (res: {
    [x: string]: any;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }) => any
> =>
  createSelector(selectAllRates, (rates: { [x: string]: any }) => {
    const filtered = codes.reduce(
      (obj: any, key: string | number) => ({
        ...obj,
        [key]: rates && rates[key],
      }),
      {}
    );
    return filtered;
  });
